import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { useSession } from 'next-auth/react';

var SessionAvailable = false;
export const useAxios = (overrideApiUrl = null) => {
    // Instellen van de basis-URL en standaardinstellingen
    axios.defaults.baseURL = overrideApiUrl
        ? `${process.env.NEXT_PUBLIC_BACKEND}`
        : `${process.env.NEXT_PUBLIC_BACKEND}/api`;
    axios.defaults.withCredentials = true;
    axios.defaults.withXSRFToken = true;
    axios.defaults.headers.common['Accept'] = 'application/json';
    const { data: session, status } = useSession();

    // Axios-interceptor voor foutafhandeling
    axios.interceptors.response.use(undefined, function (error) {
        if (error?.response?.status === 422) {
            let meldingen = [];
            for (const [keyVeldnaam, valArr] of Object.entries(error.response.data.errors)) {
                meldingen = meldingen.concat(valArr);
            }
            error.inputMessage = meldingen;
        }

        return Promise.reject(error);
    });

    useEffect(() => {
        if (session?.accessToken) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${session.accessToken}`;
        }
        SessionAvailable = status !== "loading"

    },[status, session])



    const waitForSessionReady = () => {
        return new Promise((resolve) => {
            if (SessionAvailable) {
                return resolve();
            }
            setTimeout(() => resolve(waitForSessionReady()), 100);
        });
    };


    const get = async (url, params) => {
        await waitForSessionReady();
        return axios.get(url, params);

    };


    const post = async (url, data) => {
        await waitForSessionReady();
        return axios.post(url, data)
    };

    const put = async (url, data) => {
        await waitForSessionReady();
        return axios.put(url, data)
    };

    const del = async (url, data) => {
        await waitForSessionReady();
        return axios.delete(url, data)
    };

    const all = async (axiosCalls) => {
        await waitForSessionReady();
        return axios.all(axiosCalls)
    };

    return { get, post, put, del, all };
};
